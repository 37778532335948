@tailwind base;
@tailwind components;
@tailwind utilities;

.outline-lightblue {
  outline: 3px solid rgb(0, 255, 251);
}

.bg-custom-gradient {
  background-image: linear-gradient(
    180deg,
    rgba(8, 73, 220, 1) 0%,
    rgba(196, 203, 204, 1) 72%,
    rgba(94, 186, 231, 1) 100%
  );
}

@keyframes shrink-expand {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.animate-shrink-expand {
  animation: shrink-expand 0.6s ease-in-out;
}

/* Add this to your CSS file */
/* Add this to your CSS file */
.drop-shadow-glow {
  box-shadow: 20px 20px 20px rgb(99, 255, 226); /* Initial light blue glow */
  animation: breath 2s infinite;
}

@keyframes breath {
  0%,
  100% {
    box-shadow: 0 0 20px rgba(99, 255, 226, 0.8);
  }
  50% {
    box-shadow: 0 0 60px rgba(99, 255, 226, 1);
  }
}

@font-face {
  font-family: "Muli-SemiBold";
  src: url("./assets/asetgame/muli/Muli-SemiBold.ttf") format("truetype");
  font-weight: 600; /* Adjust font weight as per your font's specifications */
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("./assets/asetgame/muli/Muli.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
